.body {
    height: 100vh;
    flex: 0.8;
    color: white;
    padding: 30px;
    background: linear-gradient(rgb(91, 87, 115), rgba(0, 0, 0, 1));
    overflow-y: overlay;
  }
  .body::-webkit-scrollbar {
    display: none;
  }
  .body__info {
    display: flex;
    align-items: flex-end;
    padding: 10px;
  }
  .body__infoText {
    flex: 1;
  }
  .body__infoText > h2 {
    font-size: 48px;
    margin-bottom: 10px;
  }
  .body__infoText > p {
    font-size: 14px;
  }
  .body__info > img {
    height: 20vw;
    margin: 0 20px;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5);
  }
  .body__icons {
    display: flex;
    align-items: center;
  }
  .body__icons > .MuiSvgIcon-root {
    margin-right: 20px;
  }
  .body__shuffle {
    font-size: 80px !important;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .body__shuffle:hover {
    transition: 100ms transform ease-in;
    transform: scale(1.08);
  }
  .body__songs {
    margin: 20px -30px;
  }